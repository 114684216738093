@import "src/assets/styles/helpers";

.content {
  display: none;
}

.element {
  margin-bottom: 0.8rem;
  padding: 0.8rem 0;
  border-bottom: 1px solid transparentize(color("bali-hay"), 0.8);
  .heading {
    display: flex;
    align-items: center;
    color: color("bali-hay");
    cursor: pointer;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 2.4rem;
    text-transform: uppercase;
    margin-bottom: 0.8rem;
  }

  .arrow {
    width: 0.8rem;
    height: 0.8rem;
    min-width: 0.8rem;
    margin-left: auto;
    fill: color("bali-hay");
    transform: rotate(90deg);
  }

  &.isOpen {
    & > .content {
      display: block;
    }
    .arrow {
      transform: rotate(-90deg);
    }
  }
  &.isActive {
    .heading {
      color: var(--color-text);
    }
  }
  &:last-of-type {
    border-bottom: 0;
  }
}
