@import "src/assets/styles/helpers";

.wrapper {
  position: relative;
  display: flex;
  height: 10rem;
  background-color: color("whisper");
  border-radius: 6px;
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 1.6rem;
  }

  &:hover .border {
    border: 1px solid color("cornflower-blue");
  }
}

.input:checked ~ .border {
  border: 1px solid color("cornflower-blue");
}

.info {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1.5rem 2.5rem;
}
.iconWrapper {
  display: flex;
  align-items: center;
  width: 8.3rem;
  min-width: 6.5rem;
}

.title {
  display: block;
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-weight: 500;
  margin-bottom: 0.8rem;
}

.text {
  display: block;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: color("bali-hay");
}

.border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
}
