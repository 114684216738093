@import "src/assets/styles/helpers";

.element {
  display: flex;
  align-items: center;
  border-bottom: 1px solid transparentize(color("bali-hay"), 0.8);
  min-height: 48px;

  &:hover {
    background-color: rgba(86, 111, 246, 0.05);
  }

  & > * {
    color: var(--color-text);
    font-size: 1.4rem;
    line-height: 1.6rem;
    font-weight: normal;
  }
	& > button {
		display: flex;
		width: 100%;
		font-size: 1.4rem;
		line-height: 1.6rem;
		padding: 1.6rem;
	}

  &:last-child {
    border-bottom: 0;
  }
}
