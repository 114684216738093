@import "src/assets/styles/helpers";

.element {
  display: flex;
  flex-direction: column;
  border: 1px solid color("iron");
  border-radius: 6px;
  padding: 0 1.6rem;
  margin: 2.4rem 0;
  height: 100%;
  max-height: 70vh;
  overflow: auto;
  @include scrollbar(4px, color("cornflower-blue"), var(--color-sidebar));
}

.listItem {
  list-style: none;
  display: flex;
  align-items: center;
  border-bottom: 1px solid color("iron");
  &:last-child {
    border-bottom: 0;
  }
}

.empty {
  display: flex;
  flex-direction: column;
  color: color("iron");
  padding: 1.6rem 0;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 300;
  margin: auto;
}
