@import "src/assets/styles/helpers";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.element {
  display: inline-flex;
  flex-direction: column;
}

.text {
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: var(--color-text);
}

.dots {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.8rem;
}

.dot {
  display: inline-flex;
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  background-color: color("cornflower-blue");
  margin: 0 0.4rem;
  animation: jumper 1s ease-out infinite;
  animation-fill-mode: forwards;

  &:nth-child(2) {
    margin-bottom: 0.4rem;
    animation-delay: 0.3s;
  }
  &:nth-child(3) {
    animation-delay: 0.5s;
  }
}

@keyframes jumper {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-150%);
  }
  100% {
    transform: translateY(0);
  }
}
