@import "src/assets/styles/helpers";

.wrapper {
  position: relative;
  margin-bottom: 2.4rem;
}

.label {
  display: block;
  color: color("bombay");
  font-size: 1.2rem;
  line-height: 2.4rem;
  text-transform: uppercase;
  font-weight: 500;
  margin: 0;
}

.icon {
  fill: color("select-icon-color");
  transform: rotate(90deg);
  width: 0.5rem;
  height: 0.8rem;
}

.error {
  position: absolute;
  bottom: -1.8rem;
  left: 0;
  color: color("widgets-torch-red");
  font-size: 1rem;
  line-height: 1.6rem;
}

.select {
  &.isPlain {
    [class$="-control"] {
      border: 1px solid var(--color-input-border-plain);
      &:hover {
        border: 1px solid var(--color-input-border-plain);
      }
      &:focus-within {
        border: 1px solid var(--color-text);
      }
    }
  }

  [class$="-control"] {
    background-color: var(--color-input-background);
    color: var(--color-text);
    border: 1px solid var(--color-input-border);
    transition: none;
    &:hover {
      border: 1px solid var(--color-input-border);
    }
    &:focus-within {
      border: 1px solid var(--color-text);
    }
  }
  [class$="-menu"] {
    background-color: var(--color-panel-bg);
  }
  [class$="-singleValue"] {
    color: var(--color-text);
  }
  [class$="-option"] {
    color: var(--color-text);
  }
  [class$="-Input"] {
    color: var(--color-text);
    input {
      font-family: $poppins;
    }
  }

  &.isError {
    [class$="-control"] {
      border: 1px solid color("widgets-torch-red");
    }
  }
}
[class$="-menu"] {
  background-color: var(--color-panel-bg) !important;
}

.status {
  display: inline-block;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 0.3rem;
  background-color: color("shark");
  margin-right: 0.8rem;
  &.Active {
    background-color: color("widgets-green");
  }
  &.Paused {
    background-color: color("widgets-crusta");
  }
}
