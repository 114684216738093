@import "src/assets/styles/helpers";

.wrapper {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  //max-width: 100%;
  padding: 40px 20px;
  max-width: 592px;
  margin: 0 auto;
  overflow-y: auto;
  @include scrollbar(4px, color("cornflower-blue"), var(--color-sidebar));

  @include media(sm) {
    padding: 64px 88px;
  }
  @include media(fhd) {
    max-width: 700px;
  }
}

.text {
  color: color("bali-hay");
  font-size: 1.2rem;
  line-height: 2.2rem;
}

.illustration {
  position: relative;
  display: none;
  align-items: center;
  justify-content: center;
  background-color: color("porcelain");
  width: 100%;
  background-image: url(../../assets/icons/mask.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  @include media(lg) {
    display: flex;
  }

  &::before {
    content: "";
    position: absolute;
    top: -8.8rem;
    right: -14.5rem;
    background-image: url(../../assets/icons/mask-3.svg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 45.5rem;
    height: 27.9rem;
  }
  &::after {
    content: "";
    position: absolute;
    bottom: -10.7rem;
    left: 8.3rem;
    background-image: url(../../assets/icons/mask-2.svg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 25.1rem;
    height: 24.1rem;
  }
}

.image {
  max-width: 100%;
  margin: 5rem;
}

.formWrapper {
  margin: 5rem 0;
}

.headingWrapper,
.link {
  display: flex;
  align-items: center;
}

.link {
  margin-right: 0.8rem;

  &:hover {
    .icon path {
      stroke: color("bali-hay");
    }
  }
}
