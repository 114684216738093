@import "src/assets/styles/helpers";

.wrapper {
  position: relative;
  margin-bottom: 2.4rem;
  &.isDisabled {
    opacity: 0.4;
    cursor: default;
  }
}

.label {
  display: block;
  color: color("bombay");
  font-size: 1.2rem;
  line-height: 2.4rem;
  text-transform: uppercase;
  font-weight: 500;
  margin: 0;
}

.buttonsWrapper {
  display: flex;
  justify-content: space-between;
}

.appApiWrapper {
  display: flex;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2.4rem;
  margin-top: 0.8rem;
  color: var(--color-text);
  padding-bottom: 0.6rem;
  justify-content: space-between;
  > div:nth-child(1) {
    width: 50%;
  }
  > div {
    > p {
      font-size: 1.8rem;
      margin-bottom: 10px;
    }
  }
}

.item {
  &:last-of-type {
    margin-right: 0.8rem;
  }
}

.error {
  position: absolute;
  bottom: -1.8rem;
  left: 0;
  color: color("widgets-torch-red");
  font-size: 1rem;
  line-height: 1.6rem;
}
