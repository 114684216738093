@import "src/assets/styles/helpers";

.wrapper {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 2.2rem;
  }
  &.isError {
    .input {
      border: 1px solid color("widgets-torch-red");
    }
  }
}

.input {
  width: 100%;
  min-height: 4rem;
  max-height: 4rem;
  border: 1px solid (var(--color-input-border));
  padding: 0.8rem 1.6rem;
  font-size: 1.2rem;
  line-height: 2.2rem;
  font-family: $poppins;
  background-color: var(--color-input-background);
  outline: none;
  border-radius: 3px;
  cursor: pointer;
  &:hover {
    .button {
      background-color: transparentize(color("cornflower-blue"), 0.9);
    }
  }
  &:focus,
  &:active {
    .button {
      background-color: color("cornflower-blue");
      color: color("white");
    }
  }
}

.hidden {
  display: none;
}

.button {
  pointer-events: none;
}
.text {
  margin-left: 0.9rem;
  color: color("bali-hay");
}
