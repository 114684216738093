@import "src/assets/styles/helpers";

.element {
  position: relative;
  z-index: 1;
  width: 100%;

  &.hasBorder {
    &::before {
      content: "";
      position: absolute;
      display: inline-flex;
      left: 1rem;
      top: 10px;
      bottom: 10px;
      width: 1px;
      background: color("iron");
      z-index: -1;
    }
  }
}
