@import "src/assets/styles/helpers";

.wrapper {
  white-space: nowrap;
}

.status {
  display: inline-block;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 0.3rem;
  background-color: color("shark");
  margin-right: 0.4rem;

  &.isActive,
  &.isRunning,
  &.isStable,
  &.isOnline {
    background-color: color("logo-green");

    &.alert {
      background-color: color("widgets-green");
    }
  }
  &.isPending {
    background-color: color("flamingo");
  }

  &.isStopped,
  &.isPaused {
    background-color: color("iron");
    &.alert {
      background-color: color("widgets-crusta");
    }
    &.agent {
      background-color: color("flamingo");
    }
  }

  &.isUnknown,
  &.isExpire,
  &.isDisabled,
  &.isOffline {
    background-color: color("iron");
  }
}

.progressBar {
  position: relative;
  width: 100%;
  min-width: 6rem;
  height: 0.8rem;
  background-color: color("progress-bar");
  border-radius: 2px;
  margin-right: 1.7rem;
}

.progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 0.8rem;
  border-radius: 2px;
}

.text {
  text-transform: capitalize;
}
