@import "src/assets/styles/helpers";


.element {
	display: flex;
	align-items: stretch;
}

.icon {
	margin-left: 0.8rem;

}
