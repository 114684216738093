@import "src/assets/styles/helpers";

.col {
  display: flex;
  margin-bottom: 2.8rem;
  &.left {
    flex-direction: column;
    margin-right: 2.8rem;
  }
  &.right {
    align-items: center;
    margin-left: auto;
    flex-wrap: wrap;
  }
}
.description {
  color: color("bali-hay");
  font-size: 1.4rem;
}

.imageWrapper {
  width: 3.2rem;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.6rem;
  overflow: hidden;

  &::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 100%;
  }

  img,
  svg {
    position: absolute;
    width: 100%;
    height: auto;
    min-height: 100%;
    object-fit: contain;
  }
}
