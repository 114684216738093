@import "src/assets/styles/helpers";

.element {
	position: fixed;
	bottom: 1.6rem;
	right: 1.6rem;
}

.toast {
	display: flex;
	align-items: center;
	background-color: var(--color-sidebar);
	box-shadow: 2px 5px 10px rgba(25, 40, 60, 0.15);
	border-radius: 3px;
	padding: 1.2rem 1.6rem;
	margin-bottom: 1.6rem;

	&.is-message {
		border-left: 3px solid color('widgets-blue');
		.close {
			margin-left: 3.2rem;
			svg {
				width: 1rem;
				height: 1rem;
				path {
					stroke: color('bali-hay');
				}
			}

			&:hover {
				svg {
					path {
						stroke: color('widgets-blue');
					}
				}
			}
		}
		.icon {
			svg {
				fill: color('widgets-blue');
			}
		}
	}

	&.is-error {
		border-left: 3px solid color('widgets-torch-red');
		.close {
			margin-left: 3.2rem;
			svg {
				width: 1rem;
				height: 1rem;
				path {
					stroke: color('bali-hay');
				}
			}

			&:hover {
				svg {
					path {
						stroke: color('widgets-torch-red');
					}
				}
			}
		}
		.icon {
			svg {
				fill: color('widgets-torch-red');
			}
		}
	}
}

.content {
	display: flex;
	flex-direction: column;
	font-size: 1.2rem;
	line-height: 1.4;
	color: color('bali-hay');
}

.type {
	font-weight: 500;
	font-size: 1.3rem;
	color: var(--color-text);
}

.icon {
	display: flex;
	align-items: center;
	width: 2rem;
	height: 2rem;
	margin-right: 1.6rem;
	svg {
		width: 100%;
		height: 100%;
	}
}

.ToastEnterActive {
	opacity: 1;
	transform: translateX(0);
	transition: opacity 300ms ease-in, transform 300ms ease;
}
.ToastEnterDone {
	opacity: 1;
	transform: translateX(0);
}
.ToastExit {
	opacity: 1;
	transform: translateX(0);
	transition: opacity 300ms ease-in, transform 300ms ease;
}
.ToastExitActive {
	opacity: 0;
	transform: translateX(100%);
}
