@import "src/assets/styles/helpers";

.wrapper {
  margin-top: 1.2rem;
}

.imageWrapper {
  width: 3.2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.6rem;

  img {
    max-width: 100%;
  }
}

.name {
  display: block;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.6rem;
  white-space: nowrap;
  color: var(--color-text);
}

.version {
  display: block;
  font-size: 1rem;
  line-height: 1.6rem;
  color: color("bali-hay");
  margin-top: 0.8rem;
}
