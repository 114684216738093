@import "src/assets/styles/helpers";

.element {
  padding: 1.2rem 2.4rem;
  position: relative;
  display: flex;
  align-items: flex-start;

  &::before {
    content: "";
    display: inline-flex;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0.2rem;
    background-color: color("cornflower-blue");
    transform: translateX(-100%);
    transition: $time-transition ease;
  }

  &::after {
    content: "";
    display: inline-flex;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: transparentize(color("cornflower-blue"), 0.95);
    opacity: 0;
    visibility: hidden;
    transition: $time-transition ease;
  }
  &:hover {
    &::before {
      transform: translateX(0);
    }
    &::after {
      opacity: 1;
      visibility: visible;
    }
  }
  &.isAlert {
    &::before {
      background-color: color("carnation");
      transform: translateX(0);
    }
    &::after {
      background-color: transparentize(color("carnation"), 0.95);
      opacity: 1;
      visibility: visible;
    }
  }
}

.dot {
  position: relative;
  top: 0.6rem;
  display: inline-flex;
  width: 0.6rem;
  min-width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  background-color: color("feijoa");
  margin-right: 1.2rem;
}

.text {
  margin-bottom: 0.4rem;
  font-size: 1.2rem;
  line-height: 2rem;
}
.date {
  font-size: 1.2rem;
  color: color("bali-hay");
  line-height: 2rem;
}

.label {
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  line-height: 2.2rem;
}

.list {
  height: 100%;
  overflow-x: hidden;
  @include scrollbar(4px, color("cornflower-blue"), var(--color-sidebar));
}
