@import "src/assets/styles/helpers";

.tabs {
  margin-bottom: 2rem;
}

.buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
