@import "src/assets/styles/helpers";

.element {
  margin-top: 2.4rem;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4rem;
  flex-wrap: wrap;
}

.text {
  color: color("bali-hay");
  font-size: 1.4rem;
  line-height: 1.6rem;
  margin-left: 2rem;
}

.link {
  color: color("cornflower-blue");

  &:hover {
    color: color("btn-color-hover");
  }
}

.errorMessage {
  display: flex;
  width: 100%;
  margin-bottom: 2.4rem;
  color: color("widgets-torch-red");
  text-transform: uppercase;
  border: 1px solid transparentize(color("bali-hay"), 0.85);
  border-radius: 6px;
  padding: 1.6rem 2.4rem;
  font-size: 1.2rem;
  box-shadow: 0 6px 12px -3px transparentize(color("bali-hay"), 0.8);
}

.confirmMessage {
  margin-top: 2.4rem;
}
