@import "src/assets/styles/helpers";

.element {
  font-size: 3.2rem;
  line-height: 4.8rem;
  color: var(--color-text);

  &.h2 {
    font-size: 2rem;
    line-height: 3.2rem;
  }

  &.h3 {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  &.h4 {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.medium {
    font-weight: 500;
  }

  &.semi-bold {
    font-weight: 600;
  }

  &.bold {
    font-weight: 700;
  }
}

.back {
  width: 1.6rem;
  height: 2rem;
  display: inline-flex;
  margin-right: 0.8rem;
  svg {
    width: 100%;
    height: 100%;
    path {
      stroke: var(--color-text);
    }
  }
  &:hover {
    svg path {
      stroke: color("bali-hay");
    }
  }
  &:focus,
  &:active {
    svg path {
      stroke: color("btn-color-click");
    }
  }
}
