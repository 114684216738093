@import "src/assets/styles/helpers";

.wrapper {
  margin-bottom: 2.2rem;
}

.title {
  display: block;
  color: color("bombay");
  font-size: 1.2rem;
  line-height: 2.4rem;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 0.8rem;
}

.group {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(12.6rem, 1fr));
  grid-gap: 1rem 1.6rem;
}

.element {
  display: flex;
  position: relative;
}
.box {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 1.6rem;
  min-width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  margin-right: 1rem;
  padding: 2px;
  border: 1px solid var(--color-input-border);
  transition: $time-transition ease;
}

.icon {
  width: 100%;
  height: 100%;
  background-color: var(--color-text);
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  transition: $time-transition ease;
}

.label {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 0.8rem;
  // min-width: 128px;
  border-radius: 3px;
  border: 1px solid var(--color-input-border);
  width: 100%;
  cursor: pointer;
  transition: $time-transition ease;

  &.isActive {
    border: 1px solid var(--color-text);
    .box {
      border-color: var(--color-text);
    }
    .icon {
      opacity: 1;
      visibility: visible;
    }
    .text {
      color: var(--color-text);
    }
  }
  &.isDisabled {
    opacity: 0.4;
    cursor: default;
  }
}

.text {
  font-size: 1.2rem;
  line-height: 2rem;
  color: color("bali-hay");
  transition: $time-transition ease;
}
