.row {
	&.isLarge {
		margin-left: -50px;
		margin-right: -50px;
	}
}

.column {
	&.isLarge {
		padding-left: 50px;
		padding-right: 50px;
	}
}
