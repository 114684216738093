@import "src/assets/styles/helpers";

.right {
  padding: 2.4rem 3.2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.left {
  padding: 2.4rem 3.2rem;
  background-color: var(--color-panel-bg);
  height: 100%;
}

.meta {
  margin-top: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.title {
  font-size: 1.2rem;
  line-height: 2.2rem;
}

.footer {
  display: flex;
  align-items: center;
  margin-top: auto;
}

.share {
  display: inline-flex;
  align-items: center;
  svg {
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 0.8rem;
    path {
      stroke: var(--color-text);
    }
  }
  .shareLabel {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.6rem;
    color: var(--color-text);
  }
  &:hover {
    .shareLabel {
      color: color("bali-hay");
    }
    svg {
      path {
        stroke: color("bali-hay");
      }
    }
  }

  &:focus,
  &:active {
    .shareLabel {
      color: color("btn-color-click");
    }
    svg {
      path {
        stroke: color("btn-color-click");
      }
    }
  }
}
