@import "src/assets/styles/helpers";

.element {
  margin-top: 1.6rem;
}

.text {
  display: block;
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: 400;
  padding: 1.6rem 0 1.6rem 0;
  color: var(--color-text);
  margin-bottom: 1.6rem;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 3.2rem;
}
