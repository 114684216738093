@import "src/assets/styles/helpers";

.element {
  padding: 1.2rem 0;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  line-height: 2.2rem;
  width: 100%;
}

.index {
  display: inline-flex;
  margin-right: 1.6rem;
  width: 1.6rem;
  color: var(--color-text);
  user-select: none;
}

.title {
  width: 100%;
  display: flex;
}

.actions {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.delete {
  width: 1.6rem;
  height: 1.6rem;
  margin-left: 1.6rem;
  svg {
    width: 100%;
    height: 100%;
  }
}
.share {
  width: 1.4rem;
  height: 1.4rem;
  margin-left: 2.6rem;
  svg {
    width: 100%;
    height: 100%;
    path {
      stroke: color("bali-hay");
    }
  }
}

.label {
  color: var(--color-text);
  user-select: none;
}

.tag {
  color: color("bali-hay");
  margin-right: 1rem;
  margin-left: 1rem;
  line-height: normal;
}
