@import "src/assets/styles/helpers";

.area {
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: repeat(auto-fill, minmax(264px, 1fr));
	@include media('md') {
		width: 100%;
		grid-template-columns: repeat(4, 25%);
		margin: 0 -1rem;
	}
}

.element {
	grid-column: span 1;

	@include media('md') {
		&.hasSize-2 {
			grid-column: span 2;
		}
		&.hasSize-3 {
			grid-column: span 3;
		}
		&.hasSize-4 {
			grid-column: span 4;
		}
	}
}
