@import "src/assets/styles/helpers";

.button {
  svg path {
    transition: $time-transition ease;
    stroke: var(--color-text);
  }
  &:hover {
    svg path {
      stroke: color("bali-hay");
    }
  }
  &:focus,
  &:active {
    svg path {
      stroke: color("btn-color-click");
    }
  }
}
