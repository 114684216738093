@import "src/assets/styles/helpers";

.element {
  width: 100%;
  border-radius: 6px;
  background-color: var(--color-panel-bg);
  margin-bottom: 1.6rem;
}

.wrapper {
  min-height: 44vh;
  display: flex;
  flex-wrap: wrap;
}

.headerCell {
  display: flex;
  align-items: center;
  min-height: 4rem;
  padding: 0.8rem 1.6rem;
  border-bottom: 1px solid rgba(138, 151, 173, 0.2);
}

.date {
  color: color("bombay");
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 2.4rem;
}
