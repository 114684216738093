@import "src/assets/styles/helpers";

.wrapper {
  position: relative;
  margin-bottom: 2.2rem;
  &.isPlain {
    :global {
      .react-date-picker__wrapper,
      .react-daterange-picker__wrapper {
        border: 1px solid var(--color-input-border-plain);
      }
    }
  }
  &.isError {
    :global {
      .react-date-picker__wrapper,
      .react-daterange-picker__wrapper {
        border: 1px solid color("widgets-torch-red");
      }
    }
  }
}

.label {
  display: block;
  color: color("bombay");
  font-size: 1.2rem;
  line-height: 2.4rem;
  text-transform: uppercase;
  font-weight: 500;
  margin: 0;
  min-height: 2.4rem;
}

.error {
  position: absolute;
  bottom: -1.8rem;
  left: 0;
  color: color("widgets-torch-red");
  font-size: 1rem;
  line-height: 1.6rem;
}
