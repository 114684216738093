@import "src/assets/styles/helpers";

.element {
  // background-color: #292a33;
  padding: 0.8rem 1.6rem;
  border-radius: 3px;
}

.status {
  display: inline-block;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 0.3rem;
  background-color: color("shark");
  margin-right: 0.8rem;
  background-color: color("iron");
  &.connected {
    background-color: color("widgets-green");
  }
}

.title {
  width: 100%;
  color: var(--color-text);
  font-size: 1.2rem;
  text-transform: capitalize;
}
