@import "src/assets/styles/helpers";

.element {
  display: flex;
  align-items: center;

  &.isAccount {
    .imageWrapper {
      overflow: visible;
      border-radius: 0;
      img {
        border-radius: 0;
        object-fit: contain;
      }
    }
  }
}

.link {
  &:hover {
    .name {
      color: color("bali-hay");
    }
  }

  &:focus,
  &:active {
    .name {
      color: color("btn-color-click");
    }
  }
}
.imageWrapper {
  width: 3.2rem;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  overflow: hidden;
  border-radius: 50%;

  &::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 100%;
  }

  img,
  svg {
    position: absolute;
    width: 100%;
    height: auto;
    min-height: 100%;
    border-radius: 50%;
  }
}

.name {
  display: block;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.6rem;
  color: var(--color-text);
  &.noWrap {
    white-space: nowrap;
  }
}

.email {
  display: block;
  font-size: 1rem;
  line-height: 1.6rem;
  color: color("bali-hay");
  margin-top: 0.4rem;
}
