@import "variables/colors.module";
@import "variables/breakpoints.module";

$time-transition: .2s;

$poppins: "Poppins", sans-serif;

@import "./mixins/media";
@import "./mixins/fontface";
@import "./mixins/aspect-ratio";
@import "./mixins/color";
@import "./mixins/scrollbar";
