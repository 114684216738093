@import "src/assets/styles/helpers";

.wrapper {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 2.2rem;
  }
}

.input {
  width: 100%;
  min-height: 4rem;
  max-height: 4rem;
  border: 1px solid (var(--color-input-border));
  padding: 0.8rem 1.6rem;
  font-size: 1.2rem;
  line-height: 2.2rem;
  font-family: $poppins;
  background-color: var(--color-input-background);
  color: var(--color-text);
  outline: none;
  border-radius: 3px;

  &.isPlain {
    border: 1px solid var(--color-input-border-plain);
  }

  &::placeholder {
    color: color("bali-hay");
    font-size: 1.2rem;
    line-height: 2.2rem;
    font-family: $poppins;
  }
  &:focus {
    border: 1px solid (var(--color-text));
  }
}

.label {
  display: block;
  color: color("bombay");
  font-size: 1.2rem;
  line-height: 2.4rem;
  text-transform: uppercase;
  font-weight: 500;
  margin: 0;
}
