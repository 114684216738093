@import "src/assets/styles/helpers";

.sectionTitle {
  margin-bottom: 2.4rem;
  font-size: 1.8rem !important;
}

.group {
  margin-bottom: 2.4rem;
}

.select {
  [class$="-control"] {
    background-color: var(--color-input-background) !important;
  }
  [class$="-menu"] {
    background-color: var(--color-sidebar) !important;
  }
}

.selectLabel {
  display: inline-flex;
  white-space: nowrap;
  margin-bottom: 0.4rem;
  font-size: 1.2rem;
  line-height: 2.2rem;

  @include media("lg") {
    margin-bottom: 0;
  }
}

.row {
  border-bottom: 1px solid transparentize(color("bali-hay"), 0.8);
  margin-bottom: 3.2rem;
  padding-bottom: 0.8rem;

  &:last-child {
    margin-bottom: 0;
    border-bottom: 0;
  }
  &.noBorder {
    border-bottom: none;
    margin-bottom: 0;
  }
}

.panel {
  overflow: hidden;
}

.radioGroup {
  margin-top: 0.4rem;
}

.checkbox {
  margin-top: 2.4rem;
}
