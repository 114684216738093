.element {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  padding: 0 2.4rem;
}

.logo {
  width: 10rem;
  margin-left: 0.8rem;
  height: auto;
}
