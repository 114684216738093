@import "src/assets/styles/helpers";

.element {
  position: relative;
  // z-index: 1;
  padding: 1.6rem 2.4rem;
  border-radius: 6px;
  background-color: var(--color-panel-bg);
  display: flex;
  flex-direction: column;
  height: 100%;

  &.noPadding {
    padding: 0;
  }
}
