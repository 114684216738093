@import "src/assets/styles/helpers";

.apiKeyWrapper {
  position: relative;
  margin: 44px 0;

  > input {
    border-radius: 3px;
    height: 40px;
    width: 306px;
    background-color: color("white");
    border: none;
    padding: 0 16px;
  }
  > p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 6px;
  }
  > div {
    position: absolute;
    left: 275px;
    top: 35px;
    z-index: 1;
    cursor: pointer;
  }
}
