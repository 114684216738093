@import "src/assets/styles/helpers";

.element {
  display: flex;
  width: 100%;
  position: relative;
  z-index: 5;
  height: 8rem;
}
.left {
  display: flex;
  align-items: center;
  padding: 1.5rem 0;
}

.wrapper {
  display: flex;
  width: 100%;
  padding: 0 0 0 3.2rem;
}
