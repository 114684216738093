@import "src/assets/styles/helpers";

.element {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 8rem;
  bottom: 0;
  width: 100%;
  max-width: 32.5rem;
  background-color: var(--color-sidebar);
  z-index: 5;
  box-shadow: -15px 0 30px -15px rgba(0, 0, 0, 0.1);
}
