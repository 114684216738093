@import "src/assets/styles/helpers";

.element {
  display: flex;
  align-items: center;
  padding: 2.8rem 2.4rem;
  width: 100%;
  max-width: 27.2rem;
  border-bottom: 1px solid transparentize(color("bali-hay"), 0.9);

  &.isMenuHidden {
    background-color: transparent;
    border-bottom-color: transparent;
    .icon {
      transform: rotate(0);
    }
  }
  @include media("md") {
    background-color: var(--color-sidebar);
  }
}

.trigger {
  margin-left: auto;
  height: auto;
  line-height: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    display: inline-flex;
    top: -20px;
    right: -20px;
    bottom: -20px;
    left: -20px;
  }
  &:hover {
    .icon {
      fill: color("cornflower-blue");
    }
  }
}
.icon {
  fill: var(--color-text);
  transition: fill $time-transition ease;
  width: 0.8rem;
  height: 1.2rem;
  transform: rotate(180deg);
}

.burger {
  position: relative;
  display: flex;
  width: 22px;
  height: 16px;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 2.4rem;
  .plank {
    width: 100%;
    height: 2px;
    display: flex;
    background-color: color("bali-hay");
    //transition: $time-transition ease;
  }

  &.isActive {
    .plank {
      &:nth-child(1) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        transform-origin: center;
      }
      &:nth-child(2) {
        //transform: translateX(-100%);
        opacity: 0;
        visibility: hidden;
      }
      &:nth-child(3) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
}
