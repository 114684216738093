@import "src/assets/styles/helpers";
.wrapper {
  position: relative;
  height: 100%;
}
.element {
  height: 100%;
  padding: 0.9rem 3.2rem 0.9rem 2.4rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color $time-transition ease;

  &:before {
    content: "";
    position: absolute;
    top: 2.4rem;
    left: 0;
    bottom: 2.4rem;
    width: 0.1rem;
    background-color: transparentize(color("bali-hay"), 0.8);
  }
  &.isActive,
  &:hover {
    background-color: var(--color-sidebar);
    &:before {
      display: none;
    }
  }
}
.thumbnail {
  width: 4rem;
  @include aspect-ratio(1, 1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.6rem;
  overflow: hidden;
  border-radius: 50%;

  span {
    display: inline-flex;
  }

  img,
  svg {
    position: absolute;
    width: 100%;
    height: auto;
    min-height: 100%;
    border-radius: 50%;
  }
}

.role {
  font-size: 1rem;
  line-height: 1;
  color: color("bali-hay");
}

.name {
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.6rem;
  margin-bottom: 0.5rem;
}

.icon {
  fill: var(--color-text);
  transform: rotate(90deg);
  margin-left: 1.6rem;
}
