@import "src/assets/styles/helpers";

.select {
  width: 100%;
  min-width: 11rem;
  margin-right: 2.4rem;

  [class$="-menu"] {
    box-shadow: var(--color-box-shadow);
  }
  [class$="-MenuList"] {
    @include scrollbar(4px, color("cornflower-blue"), var(--color-sidebar));
  }
}

.singleValue {
  display: block;
  color: var(--color-text);
  &:first-letter {
    text-transform: uppercase;
  }
}

.singleValueIcon {
  stroke: color("bali-hay");
  margin-right: 0.8rem;
  width: 1.2rem;
  height: 1.2rem;
}
