@import "src/assets/styles/helpers";
.element {
  padding: 2.4rem 0 1.6rem 0;
  border-bottom: 1px solid var(--color-border);

  &:global(.no-border-bottom) {
    border-bottom: 0;
  }

  &.withLogo {
    padding: 1.6rem 0;
  }
}

.label {
  padding: 0 2.4rem;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 2;
  text-transform: uppercase;
  color: color("bombay");
  transition: color $time-transition ease;

  &.isLink {
    display: flex;
    align-items: center;
    width: 100%;

    .icon {
      width: 0.8rem;
      height: 0.8rem;
      fill: var(--color-text);
      margin-left: auto;
      transition: fill $time-transition ease;
    }

    &.isActive,
    &:hover {
      color: color("cornflower-blue");
      .icon {
        fill: color("cornflower-blue");
      }
    }
  }
}
