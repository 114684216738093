@import "src/assets/styles/helpers";

.element {
  width: 100%;

  :global {
    .react-daterange-picker__wrapper {
      padding: 0.8rem 1.6rem;
      border-radius: 6px;
      background-color: var(--color-input-background);
      border: none;
      font-size: 1.2rem;
      line-height: 2rem;
      width: 100%;
      border: 1px solid var(--color-input-border);

      // @include media("fhd") {
      //   padding: 1.2rem 1.6rem;
      // }
    }

    .react-daterange-picker__inputGroup {
      text-align: center;
      box-sizing: border-box;
      color: var(--color-text-light);
      display: flex;
      align-items: center;
      height: auto;
      order: 1;
      max-width: max-content;
      &__input {
        color: var(--color-text-light);
        height: auto;
      }
    }

    .react-daterange-picker__range-divider {
      color: var(--color-text-light);
      order: 1;
    }

    .react-daterange-picker__button {
      padding: 0;
      width: 1.4rem;
      order: 0;
      margin-right: 0.8rem;
      svg {
        width: 100%;
        height: 100%;
      }
    }

    .react-calendar {
      border-radius: 6px;
      margin-top: 2px;
      border: 0;
      background-color: var(--color-panel-bg);
      box-shadow: 0 8px 6px rgba(25, 40, 60, 0.04);
      padding: 1.6rem 2.4rem;
      max-width: 31.5rem;
    }

    .react-calendar__navigation {
      margin-bottom: 16px;

      &__prev-button {
        order: 1;

        &:disabled {
          background-color: transparent;
          svg {
            path {
              stroke: transparentize(color("bali-hay"), 0.5);
            }
          }
        }

        &:enabled:focus,
        &:enabled:hover {
          background-color: transparent;

          svg {
            path {
              stroke: color("cornflower-blue");
            }
          }
        }
      }

      &__next-button {
        order: 2;
        &:disabled {
          background-color: transparent;
          svg {
            path {
              stroke: transparentize(color("bali-hay"), 0.5);
            }
          }
        }

        svg {
          transform: rotate(180deg);
        }

        &:enabled:focus,
        &:enabled:hover {
          background-color: transparent;

          svg {
            path {
              stroke: color("cornflower-blue");
            }
          }
        }
      }

      &__label {
        order: 0;
        text-align: left;

        &:disabled:focus,
        &:disabled {
          background-color: transparent;
          pointer-events: none;
          cursor: default;

          .react-calendar__navigation__label__labelText {
            color: color("bali-hay");
          }
        }

        &:enabled:focus,
        &:enabled:hover {
          background-color: transparent;

          .react-calendar__navigation__label__labelText {
            color: color("cornflower-blue");
          }
        }

        &__labelText {
          font-family: $poppins;
          color: var(--color-text);
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          transition: color $time-transition ease;
        }
      }
    }

    .react-calendar__month-view__weekdays__weekday {
      abbr {
        font-family: $poppins;
        color: var(--color-text);
        text-decoration: none;
        font-weight: 500;
        font-size: 12px;
        line-height: 24px;
      }
    }

    .react-calendar__tile {
      height: auto !important;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin: 1px 0;

      abbr {
        font-family: $poppins;
        position: absolute;
        color: var(--color-text-light);
        font-size: 14px;
        line-height: 16px;
      }
      &:disabled {
        background-color: transparent;
        cursor: default;
        color: transparentize(color("bali-hay"), 0.5) !important;
        abbr {
          color: transparentize(color("bali-hay"), 0.5);
        }
        &.react-calendar__tile--hover {
          // background-color: transparent !important;
          abbr {
            color: transparentize(color("bali-hay"), 0.5) !important;
          }
        }
      }

      &.react-calendar__month-view__days__day {
        @include aspect-ratio(1, 1);
        padding: 0 !important;
      }
      &.react-calendar__century-view__decades__decade,
      &.react-calendar__decade-view__years__year {
        font-family: $poppins;
        color: color("bali-hay");
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        border-radius: 0 !important;
        padding: 1.6rem 0.8rem !important;
        &:enabled:hover {
          background-color: transparentize(color("widgets-sunset-orange"), 0.5);
          color: color("white");
        }
        &.react-calendar__tile--now {
          background-color: color("widgets-sunset-orange");
          color: color("white");
        }
      }
      &.react-calendar__year-view__months__month {
        @include aspect-ratio(2, 1);
        padding: 0.8rem !important;
        border-radius: 0 !important;
      }
    }

    .react-calendar__month-view__days__day {
      &:enabled:hover,
      &.react-calendar__tile--hover {
        background-color: transparentize(color("widgets-sunset-orange"), 0.5);

        abbr {
          color: color("white");
        }
      }
    }

    .react-calendar__tile--range {
      background-color: transparentize(color("widgets-sunset-orange"), 0.5);

      abbr {
        color: var(--color-text);
      }
    }

    .react-calendar__tile--rangeStart,
    .react-calendar__tile--rangeEnd {
      background-color: color("widgets-sunset-orange") !important;
      border-radius: 50%;
      z-index: 3;

      abbr {
        color: color("white");
      }
    }

    .react-calendar__tile--rangeStart + .react-calendar__tile--range {
      overflow: visible !important;

      &::after {
        content: "";
        right: 100%;
        top: 0;
        bottom: 0;
        height: 100%;
        background-color: transparentize(color("widgets-sunset-orange"), 0.5);
        display: inline-flex;
        position: absolute;
        width: 50%;
        z-index: 1;
      }
    }

    .react-calendar__tile--rangeEnd {
      overflow: visible !important;
      z-index: 3;

      &::after {
        content: "";
        left: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        background-color: transparentize(color("widgets-sunset-orange"), 0.5);
        display: inline-flex;
        position: absolute;
        width: 50%;
        z-index: -1;
      }
    }

    .react-calendar__tile--rangeBothEnds {
      &::after {
        content: none !important;
      }
    }

    .react-calendar__tile--now {
      &:not(.react-calendar__tile--range) {
        background-color: transparent;
      }

      abbr {
        font-weight: 500;
        // color: color("white");
      }
    }

    .react-calendar__tile--active {
      background-color: transparentize(color("widgets-sunset-orange"), 0.5);

      &:enabled:hover,
      &:enabled:focus {
        background-color: color("widgets-sunset-orange");
      }
    }

    .react-calendar__year-view__months__month {
      &.react-calendar__tile--hover {
        background-color: transparent;
      }
      &:enabled:hover {
        background-color: transparentize(color("widgets-sunset-orange"), 0.5);
        abbr {
          color: color("white");
        }
      }
      &.react-calendar__tile--now {
        background-color: color("widgets-sunset-orange");
      }
    }
  }
}
