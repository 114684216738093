@import "src/assets/styles/helpers";
.element {
	display: flex;
	align-items: center;
	padding: 1.7rem 2.5rem;
	position: relative;
	font-size: 1.4rem;
	line-height: 1.6rem;
	user-select: none;

	&::before {
		content: "";
		display: inline-flex;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		width: 5px;
		background-color: color('cornflower-blue');
		transform: translateX(-100%);
		transition: $time-transition ease;
	}

	&::after {
		content: "";
		display: inline-flex;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		background-color: transparentize(color('cornflower-blue'), 0.95);
		opacity: 0;
		visibility: hidden;
		transition: $time-transition ease;
	}

	&.isActive,
	&:hover {
		&::before {
			transform: translateX(0);
		}
		&::after {
			opacity: 1;
			visibility: visible;
		}
	}

	&.isActive {
		.title {
			font-weight: 600;
		}
	}
}

.icon {
	line-height: 1;
	display: inline-flex;
	height: 1.6rem;
	width: 1.6rem;
	svg {
		width: 100%;
		height: 100%;
		stroke: color('bali-hay');
	}
}

.title {
	display: inline-flex;
	align-items: center;
	margin-left: 0.9rem;
	font-size: 1.4rem;
	line-height: 1.6rem;
	color: var(--color-text);
}

.counter {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin-left: auto;
	padding: 0.5rem;
	border-radius: 50px;
	background-color: color('carnation');
	color: color('white');
	height: 2rem;
	min-width: 2rem;
	font-weight: 600;
	font-size: 1.2rem;
	line-height: 1.4rem;
}

.hasChildren {
	.element {
		cursor: pointer;
	}
}

.children {
	margin-bottom: 1.6rem;
}

.childrenItem {
	position: relative;

	&::before {
		content: '';
		position: absolute;
		display: flex;
		height: 100%;
		top: 0;
		left: 3rem;
		width: 0.5rem;
		border-left: 1px solid color('iron');
	}

	&::after {
		content: '';
		position: absolute;
		display: flex;
		top: 50%;
		left: 3rem;
		height: 1px;
		width: 0.8rem;
		background-color: color('iron');
	}

	&:last-child {
		&::before {
			height: 55%;
			border-radius: 0 0 0 4px;
			border-bottom: 1px solid color('iron');
			width: 1rem;
		}
		&::after {
			content: none;
		}
	}
}

.child {
	padding: 0.8rem 2.5rem 0.8rem 5rem;
}

.iconExpand {
	margin-left: 0.8rem;
	transform: rotate(180deg);
	width: 0.8rem;
	height: 0.8rem;

	&.isExpanded {
		transform: none;
	}
}

.addBtn {
	z-index: 4;
}
