.element {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	padding: 2.4rem 0 0 0;
}

.icon {
	margin-bottom: 1.6rem;
	width: 9rem;
	height: 9rem;
}

.text {
	font-weight: 500;
	font-size: 1.6rem;
	line-height: 2.4rem;
	color: var(--color-text);
}
