@import "src/assets/styles/helpers";

.element {
  white-space: nowrap;
}
.priority {
  display: inline-block;
  width: 1.6rem;
  height: 0.8rem;
  border-radius: 0.3rem;
  background-color: color("bali-hay");
  margin-right: 0.8rem;

  &.isHigh {
    background-color: color("heliotrope");
  }
  &.isNormal {
    background-color: color("portage");
  }
  &.isLow {
    background-color: color("tropical-blue");
  }
}
