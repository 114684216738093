@import "src/assets/styles/helpers";

.element {
  position: relative;
  &:not(:first-child) {
    border-top: 1px solid transparentize(color("bali-hay"), 0.8);
  }

  &.isOpen {
    &::before {
      content: "";
      position: absolute;
      display: flex;
      height: 100%;
      top: 0;
      left: -2rem;
      width: 0.5rem;
      border-left: 1px solid color("iron");
    }

    &:last-child {
      &:before {
        content: none;
      }

      .userWrapper {
        &::before {
          content: "";
          position: absolute;
          display: flex;
          top: 0;
          left: -2rem;
          border-left: 1px solid color("iron");
          height: 55%;
          border-radius: 0 0 0 4px;
          border-bottom: 1px solid color("iron");
          width: 1rem;
        }
        &::after {
          content: none;
        }
      }
    }
    .userWrapper {
      &::after {
        content: "";
        position: absolute;
        display: flex;
        top: 50%;
        left: -2rem;
        height: 1px;
        width: 0.8rem;
        background-color: color("iron");
      }
    }
  }
}

.user {
  display: flex;
  align-items: center;
  width: 100%;
  margin-right: 1.6rem;

  &.isDisabled {
    pointer-events: none;
  }

  &:hover {
    .name {
      color: color("bali-hay");
    }
  }

  &:focus,
  &:active {
    .name {
      color: color("btn-color-click");
    }
  }
  &Wrapper {
    position: relative;
    display: flex;
    align-items: center;
    padding: 1.6rem 0;
  }
}

.button {
  margin-left: auto;
  padding: 0.5rem 1rem;
}

.arrow {
  width: 0.8rem;
  height: 0.8rem;
  min-width: 0.8rem;
  margin-left: auto;
  fill: color("bali-hay");
  transform: rotate(90deg);

  &.isExpanded {
    transform: rotate(-90deg);
  }
}

.imageWrapper {
  width: 3.2rem;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;

  &::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 100%;
  }

  img,
  svg {
    position: absolute;
    width: 100%;
    height: auto;
    min-height: 100%;
    object-fit: contain;
  }
}

.name {
  display: block;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.6rem;
  text-align: left;
  color: var(--color-text);
  &.noWrap {
    white-space: nowrap;
  }
}

.email {
  display: block;
  font-size: 1rem;
  line-height: 1.6rem;
  color: color("bali-hay");
  margin-top: 0.4rem;
  text-align: left;
}

.children {
  position: relative;
  margin-left: 4rem;

  .element {
    border-top: 1px solid transparentize(color("bali-hay"), 0.8);
  }
}

.showMore {
  padding-left: 4rem;
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 500;
  margin-bottom: 2rem;
  color: var(--color-text);
  .arrow {
    margin-left: 0.6rem;
  }
}
