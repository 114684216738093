@import "src/assets/styles/helpers";

.wrapper {
  position: relative;
  margin-bottom: 2.4rem;

  &.isError {
    .input {
      border: 1px solid color("widgets-torch-red");
    }
  }
}

.input {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  min-height: 4rem;
  border: 1px solid (var(--color-input-border));
  padding: 0.8rem 1.6rem;
  font-size: 1.2rem;
  line-height: 2.2rem;
  font-family: $poppins;
  background-color: var(--color-input-background);
  color: var(--color-text);
  outline: none;
  border-radius: 3px;
  max-height: 12.6rem;
  vertical-align: top;

  &.isPlain {
    border: 1px solid var(--color-input-border-plain);
  }
  &::placeholder {
    color: color("bali-hay");
    font-size: 1.2rem;
    line-height: 2.2rem;
    font-family: $poppins;
  }
  &:focus {
    border: 1px solid (var(--color-text));
  }
  &.isDisabled {
    opacity: 0.4;
  }
}

.label {
  display: block;
  color: color("bombay");
  font-size: 1.2rem;
  line-height: 2.4rem;
  text-transform: uppercase;
  font-weight: 500;
  margin: 0;
}

.error {
  position: absolute;
  bottom: -1.8rem;
  left: 0;
  color: color("widgets-torch-red");
  font-size: 1rem;
  line-height: 1.6rem;
}
