@import "src/assets/styles/helpers";

.element {
  display: inline-flex;
  align-items: center;
  background-color: color("cornflower-blue");
  color: color("white");
  height: auto;
  min-height: 4rem;
  min-width: max-content;
  border-radius: 3px;
  font-weight: 600;
  border: none;
  font-family: $poppins;
  transition: $time-transition ease;
  user-select: none;
  font-size: 1.4rem;
  padding: 1.2rem 1.6rem;
  line-height: 1.6rem;

  &:hover {
    background-color: color("btn-color-hover");
  }

  &:focus,
  &:active {
    background-color: color("btn-color-click");
  }

  &.isOutline {
    text-align: center;
    position: relative;
    min-height: unset;
    font-weight: 400;
    color: color("cornflower-blue");
    border: 1px solid color("cornflower-blue");
    background-color: transparent;
    font-size: 1.2rem;
    padding: 0.2rem 0.7rem;
    line-height: 1.8rem;
    margin-right: 0.8rem;

    &:hover {
      background-color: transparentize(color("cornflower-blue"), 0.9);
    }

    &:focus,
    &:active {
      background-color: color("cornflower-blue");
      color: color("white");
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  &.inTooltip {
    font-size: 1.4rem;
    line-height: 1.6rem;
    width: 100%;
    // height: 48px;
    background-color: transparent;
    color: var(--color-text);
    border-radius: 0;
    padding: 1.6rem;
  }

  &.isPlain {
    background-color: transparent;
    color: var(--color-text);
    margin-right: 3.2rem;
    padding: 1.2rem 0;

    &:hover {
      color: color("bali-hay");
    }

    &:focus,
    &:active {
      color: color("btn-color-click");
    }
  }

  &.isOrange {
    padding: 0.2rem 0.8rem;
    font-size: 1.2rem;
    line-height: 1.8rem;
    color: color("flamingo");
    border: 1px solid color("flamingo");
    margin: 0 auto;
    &:hover {
      background-color: transparentize(color("flamingo"), 0.9);
    }

    &:focus,
    &:active {
      background-color: color("flamingo");
      color: color("white");
    }
  }

  &.isSmall {
    font-size: 1.2rem;
    line-height: 2.2rem;
    min-height: auto;
    margin-bottom: 1rem;
    margin-right: 0;
    padding: 0;
  }

  &.iconRight {
    & > * {
      margin-left: 0.9rem;
    }
  }
  &.iconLeft {
    & > * {
      margin-right: 0.9rem;
    }
  }
  svg {
    min-width: 1.2rem;
    height: 100%;
  }
}
