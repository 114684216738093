.content {
  margin-top: 1.6rem;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 3.2rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
