@import "src/assets/styles/helpers";

.element {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1.7rem 2.5rem;
  user-select: none;

  &::before {
    content: "";
    display: inline-flex;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 5px;
    background-color: color("cornflower-blue");
    transform: translateX(-100%);
    transition: $time-transition ease;
  }

  &::after {
    content: "";
    display: inline-flex;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: transparentize(color("cornflower-blue"), 0.95);
    opacity: 0;
    visibility: hidden;
    transition: $time-transition ease;
  }

  &.isActive,
  &:hover {
    &::before {
      transform: translateX(0);
    }

    &::after {
      opacity: 1;
      visibility: visible;
    }
  }
}

.title {
  font-size: 1.4rem;
  line-height: 2rem;
  color: var(--color-text);
  font-weight: 500;
}
