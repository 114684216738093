@import "src/assets/styles/helpers";

.element {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  overflow: hidden;
}

.list {
  display: flex;
  border-radius: 6px;
  background-color: var(--color-panel-bg);
  margin-bottom: 1.6rem;
  overflow-x: auto;
  white-space: nowrap;
  &.inChart {
    background-color: transparent;
    border: 1px solid transparentize(color("bali-hay"), 0.8);
    border-radius: 4px;
    margin-bottom: 0;

    @include media("md") {
      margin-left: 1.6rem;
    }
  }

  &.isBgWhite {
    border: 1px solid var(--color-tabs-border);
    margin-bottom: 0;
  }
}

.button {
  position: relative;
  display: flex;
  height: 100%;
  line-height: 1.6rem;
  font-size: 1rem;
  font-weight: 500;
  color: color("bali-hay");
  padding: 0.8rem 1.6rem;
  font-family: $poppins;
  transition: $time-transition ease;

  &:not(:last-child):before {
    content: "";
    position: absolute;
    top: 4px;
    bottom: 4px;
    right: 0;
    width: 1px;
    background-color: color("table-border");
  }

  &.inChart {
    border-right: 1px solid transparentize(color("bali-hay"), 0.8);

    &:before {
      display: none;
    }
    &:last-child {
      border-right: 0;
    }
    &:hover,
    &.isActive {
      background-color: transparentize(color("cornflower-blue"), 0.95);
    }
  }
  &:hover,
  &.isActive {
    color: color("cornflower-blue");
  }
}
