@import "src/assets/styles/helpers";

.icon {
  fill: color("white");
}

.dateWrapper {
  > div {
    margin-bottom: 0;
  }
}

.fileOptions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
}

.htmlWrapper {
  max-height: 600px;
  overflow: auto;
  table {
    margin: auto;
  }
}

.htmlLoader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (min-width: 1921px) {
  .select {
    > div {
      height: 52px;
    }
  }
}
