@import "src/assets/styles/helpers";

.element {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;
  &.inTable {
    position: static;
    .content {
      top: auto;
      right: 11px;
      transform: translateY(28px);
    }
  }
}

.content {
  position: absolute;
  z-index: 3;
  right: -50%;
  top: -100%;
  background-color: var(--color-tooltip);
  box-shadow: 0 8px 6px rgba(25, 40, 60, 0.04);
  border-radius: 6px;
  width: max-content;
  min-width: 144px;
  overflow: hidden;
}

.trigger {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  margin-left: auto;
}
.dot {
  display: inline-flex;
  width: 0.3rem;
  min-width: 0.3rem;
  height: 0.3rem;
  align-items: center;
  margin: 0 0.1rem;
  background-color: var(--color-text);
  border-radius: 50%;
}
