@import "src/assets/styles/helpers";

.element {
  background: var(--color-background);
  color: var(--color-text);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
}

.content {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100% - 12rem);

	@include media('md') {
		height: calc(100% - 8rem);
	}
}

.wrapper {
  padding: 2.8rem 2.4rem;
  overflow-y: auto;
	overflow-x: hidden;
  @include scrollbar(4px, color("cornflower-blue"), var(--color-background));
  width: 100%;

  transition: margin $time-transition ease;

	@include media('md') {
		padding: 2.8rem 3.2rem;
	}
  @include media("lg") {
    margin-left: 27.2rem;
  }
  &.isExpanded {
    margin-left: 0;
  }
}

.selectBar {
	box-shadow: 0 8px 6px 0 rgba(25, 40, 60, 0.04);
	border-bottom: 1px solid transparentize(color('bali-hay'), 0.9);
}
