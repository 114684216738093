@import "src/assets/styles/helpers";

.list {
  margin-top: 0.8rem;
}

.element {
  display: flex;
  align-items: center;
  padding: 0.8rem 2.5rem;
  position: relative;
  font-size: 1.4rem;
  line-height: 1.6rem;
  user-select: none;

  &::before {
    content: "";
    display: inline-flex;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 5px;
    background-color: color("cornflower-blue");
    transform: translateX(-100%);
    transition: $time-transition ease;
  }

  &::after {
    content: "";
    display: inline-flex;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: transparentize(color("cornflower-blue"), 0.95);
    opacity: 0;
    visibility: hidden;
    transition: $time-transition ease;
  }

  &.isActive,
  &:hover {
    &::before {
      transform: translateX(0);
    }

    &::after {
      opacity: 1;
      visibility: visible;
    }
  }

  &.isActive {
    .title {
      font-weight: 600;
    }
  }
}

.icon {
  line-height: 1;
  display: inline-flex;
  // height: 1.6rem;
  // width: 1.6rem;
  width: 0.8rem;
  height: 0.8rem;
  svg {
    width: 100%;
    height: 100%;
    fill: var(--color-text);
  }
  // path {
  //   fill: color("bali-hay");
  // }
}

.title {
  margin-left: 0.9rem;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: var(--color-text);
}
