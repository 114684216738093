@import "src/assets/styles/helpers";

.element {
  display: flex;
  flex-direction: column;
  margin-top: 2.4rem;
  min-height: 20vh;

  table {
    tbody tr:first-child {
      border-top: none !important;
    }
  }
}

.table {
  margin-bottom: 4rem;
}

.checkbox {
  color: var(--color-text);
  margin: 2.2rem 0 4.4rem 0;
}

.footer {
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
}

.icon path {
  stroke: color("white");
}

.text {
  display: block;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 400;
  color: var(--color-text);
  margin: 4rem 0;
}

.switch {
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
}
