@import "src/assets/styles/helpers";

.element {
  position: relative;
  background-color: var(--color-sidebar);
  padding: 0 3.2rem;
  border-radius: 6px;
  width: 48rem;
  max-height: 100%;
  overflow: auto;
  @include scrollbar(4px, color("cornflower-blue"), var(--color-sidebar));

  table {
    tbody tr:first-child {
      border-top: none !important;
    }
  }
}

.button {
  position: absolute;
  z-index: 3;
  top: 2.7rem;
  right: 0;
}

.header,
.footer {
  position: sticky;
  background-color: var(--color-sidebar);
  z-index: 2;
  padding: 2.4rem 0;
}

.header {
  top: 0;
}

.footer {
  bottom: 0;
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
}

.td {
  padding: 1.6rem 0;
  font-size: 1.4rem;
  line-height: 1.6rem;
  white-space: nowrap;
}

.customCell {
  font-size: 1.2rem;
  padding-left: 1.8rem;
  white-space: initial;
}

.checkboxCell {
  width: 1px;
}

.fieldWrapper {
  margin-right: 1.1rem;
}
