@import "src/assets/styles/helpers";

.element {
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-align: left;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: var(--color-text);
}
.icon {
  width: 1.4rem;
  height: 1.4rem;
  min-width: 1.4rem;
  margin-right: 0.8rem;
}

.children {
  display: inline-flex;
  flex-direction: column;
  &:hover {
    color: color("bali-hay");
  }
  &:focus,
  &:active {
    color: color("btn-color-click");
  }
}
