@import "src/assets/styles/helpers";

.element {
  &.noWrap {
    white-space: nowrap;
  }
  &.isBold {
    font-weight: 600;
    font-size: 1.4rem;
  }
  &.isLight {
    color: color("bali-hay");
  }

  &.link {
    transition: $time-transition ease;
    &:hover {
      color: color("bali-hay");
    }

    &:focus,
    &:active {
      color: color("btn-color-click");
    }
  }
}

.name {
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-weight: 600;
}

.id {
  font-size: 1rem;
  line-height: 1.6rem;
  font-weight: 500;
  color: color("bali-hay");
  margin-top: 0.8rem;
}
