@import "src/assets/styles/helpers";

.arrow {
  transform: rotate(180deg);
  path {
    stroke: color("white");
  }
}

.navigation {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 4px;
    border-radius: 6px;
    background-color: var(--color-panel-bg);
  }
}

.header {
  margin-bottom: 1.6rem;
}

.switcher {
  justify-content: space-between;
  margin-bottom: 2.2rem;
}

.errorMessage {
  color: #fb153c;
  font-size: 1rem;
  margin-top: -2rem;
}

.removeEmail {
  text-align: right;
  color: #fb153c;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 16px;
}

.addEmail {
  text-align: right;
  color: #03a400;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 16px;
}

.alertForm {
  input, .react-daterange-picker__wrapper {
    border-color: var(--color-input-border) !important;
  }
}

.navigationItem {
  display: inline-flex;
  flex-direction: column;
  padding: 1rem 0;
  cursor: pointer;
  margin-right: 6rem;
  position: relative;
  z-index: 2;

  &:last-child {
    margin-right: 0;
  }

  .navigationItemLabel {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.6rem;
    color: color("bali-hay");
    margin-bottom: 0.4rem;
    transition: $time-transition ease;
  }

  .navigationItemTitle {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.6rem;
  }

  &:hover {
    .navigationItemLabel {
      color: color("cornflower-blue");
    }
  }

  &.isActive {
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 4px;
      border-radius: 6px;
      background-color: color("cornflower-blue");
    }
    .navigationItemLabel {
      color: color("cornflower-blue");
    }
  }
}
.content {
  margin-top: 3.2rem;
}
