.wrapper {
  display: inline-flex;
  align-items: center;
  border-radius: 6px;
  background-color: var(--color-panel-bg);
  margin: 0 1.6rem 1.6rem 0;
}

.dash {
  color: var(--color-text-light);
}
