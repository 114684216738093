@import "src/assets/styles/helpers";

.element {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 2.2rem;
  color: color("bali-hay");
  text-align: center;
  margin: 4rem 0;

  &.withImage {
    height: auto;
    margin-top: 10rem;
  }
}

.title {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.4rem;
  color: var(--color-text);
}

.subtitle {
  margin-top: 1rem;
}

.image {
  margin-bottom: 2.5rem;
}
