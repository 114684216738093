@import "src/assets/styles/helpers";

.element {
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: none;
    color: color("cornflower-blue");
  }
}

.text {
  margin-left: 1.3rem;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.4rem;
  user-select: none;
}

.logo {
  height: auto;
  width: 100%;
  @include media(sm) {
    height: 4rem;
  }
}

.SignInLogo {
  height: auto;
  width: 100%;
  @include media(sm) {
    height: 10rem;
  }
}
