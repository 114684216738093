@import "src/assets/styles/helpers";

.header {
  padding: 1.6rem;
}

.wrapper {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.scrollable {
  width: 100%;
  max-width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  border-radius: 6px;
  @include scrollbar(4px, color("cornflower-blue"), var(--color-panel-bg));
}

.element {
  border-collapse: collapse;
  width: 100%;
  tbody tr {
    border-top: 1px solid color("table-border");
  }

  &.withStatus td:first-child {
    height: 1px;
    padding: 0;
  }
}

.th {
  font-size: 1.2rem;
  line-height: 2rem;
  font-weight: 500;
  color: color("bombay");
  text-transform: uppercase;
  text-align: left;
  border: none;
  padding: 1rem 1.6rem;
  height: auto;
  white-space: nowrap;
  user-select: none;
}

.td {
  padding: 1.6rem;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 400;
  color: var(--color-text);
  vertical-align: middle;
}

.p-0 {
  padding: 0;
}

.sorting {
  margin-left: 0.8rem;
}
.iconArrow {
  fill: transparentize(color("bali-hay"), 0.7);
  transform: rotate(90deg);
  width: 0.5rem;
  height: 0.8rem;

  &.isDown {
    transform: rotate(270deg);
    margin-left: 0.4rem;
  }

  &.isActive {
    fill: color("bali-hay");
  }
}

.pagination {
  display: flex;
  align-items: center;
  padding: 2.4rem 1.6rem;
  border-top: 1px solid color("table-border");
  font-size: 1.4rem;
  margin-top: auto;
}

.buttonsWrapper {
  display: inline-flex;
  align-items: center;
  margin-right: 1rem;
}

.iconChevron {
  &.isRight {
    transform: rotate(180deg);
  }
}

.button {
  display: inline-flex;
  align-items: center;
  margin: 0 0.5rem;
  &:disabled {
    svg path {
      stroke: transparentize(color("bali-hay"), 0.5);
    }
  }
}
.input {
  width: 3rem;
  min-height: 2rem;
  border: 1px solid (var(--color-input-border));
  padding: 0.4rem;
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-family: $poppins;
  background-color: var(--color-input-background);
  color: var(--color-text);
  outline: none;
  border-radius: 3px;
  text-align: center;
  margin-right: 0.4rem;
  &:focus {
    border: 1px solid (var(--color-text));
  }
  &::placeholder {
    color: color("bali-hay");
    font-size: 1.2rem;
    line-height: 2.2rem;
    font-family: $poppins;
  }
  &Wrapper {
    display: inline-flex;
    align-items: center;
    margin: 0 0.8rem;
  }
}
.inputOf {
  font-size: 1.2rem;
  color: var(--color-text);
}
.select {
  margin-left: auto;

  [class$="-control"] {
    background-color: var(--color-input-background);
    color: var(--color-text);
    border: 1px solid var(--color-input-border);
    transition: none;
    &:hover {
      border: 1px solid var(--color-input-border);
    }
    &:focus-within {
      border: 1px solid var(--color-text);
    }
  }
  [class$="-menu"] {
    background-color: var(--color-sidebar) !important;
    max-width: 10rem;
  }
  [class$="-singleValue"] {
    color: var(--color-text);
  }
  [class$="-option"] {
    color: var(--color-text);
  }
  [class$="-Input"] {
    color: var(--color-text);
  }
}

.fieldWrapper {
  display: flex;
  position: relative;
}
.label {
  display: inline-flex;
  cursor: pointer;
}

.box {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-checkbox-bg) !important;
  width: 2.4rem;
  min-width: 2.4rem;
  height: 2.4rem;
  border-radius: 3px;
  padding: 0.5rem;
}

.icon {
  width: 100%;
  height: 100%;
  path {
    stroke: color("cornflower-blue");
  }
}
