@import "src/assets/styles/helpers";

.element {
  display: flex;
  align-items: center;
  padding: 0.4rem 0;
  margin-bottom: 0.8rem;
}

.title {
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin-right: 1.6rem;
  color: var(--color-text);
}

.add {
  margin-left: auto;
  position: relative;
  z-index: 3;
  cursor: pointer;
  width: 1rem;
  min-width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  &::before {
    content: "";
    position: absolute;
    top: -1rem;
    left: -1rem;
    bottom: -1rem;
    right: -1rem;
    border-radius: 3px;
    transition: background-color $time-transition ease;
  }

  &:hover {
    &::before {
      background-color: transparentize(color("bali-hay"), 0.8);
    }
  }
}

.icon {
  width: 100%;
  height: 100%;
  path {
    stroke: var(--color-text);
  }
}
