@import "src/assets/styles/helpers";

.element {
  width: 100%;
  border-radius: 6px;
  background-color: var(--color-panel-bg);
  margin-bottom: 1.6rem;
}

.cell {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 4.6rem;
  padding: 1.2rem 1.6rem;
  &.isAlert {
    &::before {
      content: "";
      display: inline-flex;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 5px;
      background-color: color("carnation");
      transform: translateX(0);
    }
    &::after {
      content: "";
      display: inline-flex;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background-color: transparentize(color("carnation"), 0.95);
      opacity: 1;
      visibility: visible;
    }
  }
}

.status {
  display: inline-block;
  width: 0.6rem;
  height: 0.6rem;
  min-width: 0.6rem;
  min-height: 0.6rem;
  border-radius: 50%;
  margin-right: 1.1rem;
  background-color: color("heliotrope");
}

.alert {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 2.4rem;
  margin-right: 1.1rem;
}

.text {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 2.2rem;
}

.time {
  @extend .text;
  margin-left: 0.8rem;
  color: color("bali-hay");
}

.button {
  margin-left: auto;
}
