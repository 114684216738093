@import "src/assets/styles/helpers";

.element {
  display: block;
  line-height: 1.8rem;
  margin-bottom: 0.6rem;
  font-weight: 500;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: color("bombay");
  transition: color $time-transition ease;

  &.isHeading {
    line-height: 2.4rem;
  }
}
