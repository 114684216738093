.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2.4rem;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 2.4rem;
  &:nth-child(2) {
    svg {
      transform: rotate(180deg);
    }
  }

  &.isDisabled {
    pointer-events: none;
    svg path {
      stroke: #8a97ad;
    }
  }
}
