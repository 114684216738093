@import "src/assets/styles/helpers";

.element {
	display: flex;
	width: 100%;
	margin-top: auto;
	padding: 2.4rem;
	border-top: 1px solid var(--color-border);
	align-items: center;
	color: var(--color-text);
	font-size: 1.4rem;
	line-height: 1.6rem;
	position: relative;

	&::before {
		content: "";
		display: inline-flex;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		width: 5px;
		background-color: color('cornflower-blue');
		transform: translateX(-100%);
		transition: $time-transition ease;
	}

	&::after {
		content: "";
		display: inline-flex;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		background-color: transparentize(color('cornflower-blue'), 0.95);
		opacity: 0;
		visibility: hidden;
		transition: $time-transition ease;
	}

	&:hover {
		&::before {
			transform: translateX(0);
		}
		&::after {
			opacity: 1;
			visibility: visible;
		}
	}
}

.icon {
	margin-right: 1rem;
	width: 1.4rem;
	height: 1.4rem;
}
