@import "src/assets/styles/helpers";

.element {
  min-width: 12rem;
  transition: $time-transition ease;
  &:hover {
    .name {
      color: color("bali-hay");
    }
  }

  &:focus,
  &:active {
    .name {
      color: color("btn-color-click");
    }
  }
}
.name {
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-weight: 600;
}

.id {
  font-size: 1rem;
  line-height: 1.6rem;
  font-weight: 500;
  color: color("bali-hay");
  margin-top: 0.8rem;
}

.label {
  display: inline-flex;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.8rem;
  padding: 0.3rem 0.8rem;
  text-transform: uppercase;
  background-color: var(--color-text);
  color: var(--color-sidebar);
  border-radius: 3px;
  margin-left: 0.8rem;
}
