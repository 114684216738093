$breakpoints: (
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
        xxl: 1440,
				fhd: 1921
);

:export {
	@each $name, $breakpoint in $breakpoints {
		#{$name}: #{$breakpoint};
	}
}
