@import "src/assets/styles/helpers";

.element {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  border-bottom: 1px solid transparentize(color("bali-hay"), 0.9);
  white-space: nowrap;
}

.item {
  display: inline-flex;
  padding: 2rem 0.6rem 1.6rem 0.6rem;
  margin: 0 1.2rem;
  justify-content: center;
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: color("bali-hay");
  position: relative;

  &::before {
    content: "";
    display: inline-flex;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.2rem;
    background-color: color("cornflower-blue");
    opacity: 0;
    visibility: hidden;
    transform: translateY(0.1rem);
  }

  &.isActive {
    color: var(--color-text);
    font-weight: 500;
    &::before {
      opacity: 1;
      visibility: visible;
    }
  }
  &:hover {
    color: var(--color-text);
  }
}
