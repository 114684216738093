@import "src/assets/styles/helpers";

.wrapper {
  max-width: 953px;
  margin-top: 2rem;

  @include media("fhd") {
    max-width: 100%;
  }
}

.column {
  @include media("md") {
    &:first-child {
      position: relative;
      padding-right: 3.2rem;
    }
    &:nth-child(2) {
      padding-left: 3.2rem;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 1px;
        background-color: rgba(210, 217, 220, 0.2);
      }
    }
  }

  @include media("lg") {
    &:first-child {
      padding-right: 6rem;
    }
    &:nth-child(2) {
      padding-left: 6rem;
    }
  }
}
.section {
  margin-bottom: 4rem;
  &Title {
    display: block;
    font-size: 1.8rem;
    line-height: 2.4rem;
    font-weight: 500;
    margin-bottom: 1.7rem;
  }
}
