@import "src/assets/styles/helpers";

.element {
  position: relative;
  background-color: var(--color-sidebar);
  padding: 0 3.2rem 3.2rem;
  border-radius: 6px;
  width: 48rem;
  max-height: 100%;
  overflow: auto;
  @include scrollbar(4px, color("cornflower-blue"), var(--color-sidebar));
}

.button {
  position: absolute;
  z-index: 3;
  top: 2.7rem;
  right: 0;
}

.header {
  position: sticky;
  top: 0;
  background-color: var(--color-sidebar);
  z-index: 2;
  padding: 2.4rem 0;
}

.search {
  margin-bottom: 2.4rem;
}

.text {
  display: block;
  text-align: center;
  margin: 2.4rem 0;
  color: var(--color-text);
}

.arrow {
  width: 0.8rem;
  height: 0.8rem;
  min-width: 0.8rem;
  margin-left: auto;
  fill: color("bali-hay");
  transform: rotate(90deg);

  &.isExpanded {
    transform: rotate(-90deg);
  }
}

.showMore {
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 500;
  margin-bottom: 2rem;
  color: var(--color-text);
  .arrow {
    margin-left: 0.6rem;
  }
}

.wrapper {
  position: relative;
}
