@import "src/assets/styles/helpers";

.element {
  width: 8.4rem;
  padding: 0.9rem 1.6rem;
  font-size: 1.2rem;
  line-height: 2.2rem;
  font-family: $poppins;
  border: none;
  border-radius: 6px;
  color: var(--color-text-light);
  background-color: var(--color-panel-bg);
  min-height: 4rem;
  max-height: 4rem;
  &:focus {
    outline: none;
  }
}
.popper {
  z-index: 99999 !important;
  margin-top: 0.2rem !important;

  :global {
    .react-datepicker {
      border: 1px solid transparent;
      background-color: var(--color-panel-bg);
      box-shadow: var(--color-box-shadow);

      &__triangle {
        display: none !important;
      }
      &__header {
        display: none !important;
      }
      &__time {
        background-color: var(--color-panel-bg) !important;

        &-container {
          width: 8.2rem !important;
        }
        &-box {
          width: 8.2rem !important;
        }
        &-list {
          @include scrollbar(
            4px,
            color("cornflower-blue"),
            var(--color-background)
          );
          &-item {
            font-size: 1.2rem;
            color: var(--color-text-light);
            font-family: $poppins;
            padding: 0.8rem 1.6rem !important;
            height: auto !important;
            &:hover {
              background-color: rgba(86, 111, 246, 0.05) !important;
            }

            &--selected {
              background-color: rgba(86, 111, 246, 0.05) !important;
              color: color("btn-color-click") !important;
              font-weight: 600 !important;
            }
            &--disabled {
              opacity: 0.4;
              color: var(--color-text-light) !important;
            }
          }
        }
      }
    }
  }
}
