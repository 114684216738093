@import "src/assets/styles/helpers";

.element {
  display: flex;
  flex-direction: column;
  margin-top: 2.4rem;
  min-height: 20vh;
}

.subTestsList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;

  > div {
    width: 95px;
    display: flex;
    flex-direction: column;
    align-items: center;
    > p {
      margin-bottom: 5px;
    }
    > div {
      width: 100%;
    }
  }
}

.checkboxCell {
  width: 1px;
}

.footer {
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
}

.wrapper {
  margin-bottom: 2rem;
  margin-top: auto;
}

.filter {
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;
  height: 38px;
  padding-left: 10px;
  margin-bottom: 13px;
}

.filter::placeholder {
  font-family: "Poppins";
}

.appContainer {
  border-bottom: 1px solid rgba(138, 151, 173, 0.2);
  margin-bottom: 13px;
}

.list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 13px 0;
  border-top: 1px solid rgba(138, 151, 173, 0.2);
  > div:nth-child(1) {
    margin-right: 12px;
    cursor: pointer;
  }
  .app {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    > div {
      width: 30%;
    }
  }
  .api {
    width: 100%;
    > div {
      width: 100%;
    }
  }
}

.fieldWrapper {
  margin-right: 1.1rem;
}
