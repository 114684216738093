.element {
  position: sticky;
  top: 0;
  max-width: 35rem;
  margin-left: auto;
  height: auto;
  padding: 3rem;
  & > ul {
    li {
      & > ul {
        margin-left: 2.4rem;
      }
    }
  }
}

.title {
  font-size: 1.8rem !important;
  font-weight: 500;
  margin-bottom: 2.2rem;
}
