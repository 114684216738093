@import "src/assets/styles/helpers";

.group {
	display: flex;
	flex-direction: column;
	margin-top: 0.8rem;

	&.isHorizontal {
		flex-direction: row;
		flex-wrap: wrap;
	}

	&.isGrid {
		display: grid;
		grid-gap: 1rem;
		grid-template-columns: repeat(auto-fill, minmax(264px, 1fr));
		@include media('md') {
			width: 100%;
			grid-template-columns: repeat(4, 25%);
		}
	}
}

