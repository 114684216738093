.content {
  margin-top: 1.6rem;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 3.2rem;
}

.selectLabel {
  color: #b0b6be;
  font-size: 1.2rem;
  line-height: 2.4rem;
  text-transform: uppercase;
  font-weight: 500;
  margin: 0;
}

.selectError {
  color: #fb153c;
  font-size: 1rem;
  line-height: 1.6rem;
}

.group {
  margin-bottom: 1.6rem;
}
