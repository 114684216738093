.element {
  background-color: var(--color-panel-bg);
  border-radius: 6px;

  table {
    tbody tr:first-child {
      border-top: none !important;
    }
  }
}

.footer {
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
}

.sm {
  width: 1px;
}

