@import "src/assets/styles/helpers";

.wrapper {
  margin-bottom: 2.2rem;
}

.element {
  display: flex;
  flex-wrap: wrap;

  .fieldWrapper {
    display: flex;
    position: relative;
    margin-bottom: 0.8rem;
    border: 1px solid var(--color-input-border);
    border-radius: 0.3rem;
    &:not(:last-child) {
      margin-right: 0.8rem;
    }
    &.isActive {
      border: 1px solid var(--color-input-border-checked);

      .label {
        color: var(--color-text-field-checked);
      }
    }
    .label {
      display: inline-flex;
      align-items: center;
      padding: 0.6rem 1.6rem;
      font-size: 1.2rem;
      line-height: 2rem;
      color: color("bali-hay");
      cursor: pointer;
    }
  }
}

.title {
  display: block;
  color: color("bombay");
  font-size: 1.2rem;
  line-height: 2.4rem;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 0.8rem;
}
