@import "src/assets/styles/helpers";

.element {
  display: flex;
  flex-direction: column;
  margin-bottom: 3.4rem;

  @include media("sm") {
    flex-direction: row;
  }
}

.picture {
  width: 18.4rem;
  @include aspect-ratio(1, 1);

  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 3rem;
  overflow: hidden;
  margin-bottom: 2rem;
  border-radius: 50%;

  &.isLogo {
    border-radius: 0;
    overflow: visible;
    img {
      position: static;
      border-radius: 0;
      object-fit: contain;
    }
  }

  @include media("sm") {
    margin-bottom: 0;
  }
  img,
  svg {
    position: absolute;
    width: 100%;
    height: auto;
    min-height: 100%;
    border-radius: 50%;
  }
  svg {
    position: absolute;
  }
}
.title {
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-weight: 500;
  margin-bottom: 0.6rem;
}
.text {
  font-size: 1.4rem;
  line-height: 1.8rem;
  opacity: 0.5;
  margin-bottom: 1.2rem;
}

.uploadBtn {
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-weight: 600;
  color: color("cornflower-blue");
  display: inline-block;
  padding: 1.2rem 0.6rem;
  margin-left: -0.6rem;
  margin-right: 1.3rem;
  cursor: pointer;
  &:hover {
    color: color("btn-color-hover");
  }

  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
}

.error {
  margin-top: 1.2rem;
  font-size: 1.4rem;
  color: color("widgets-torch-red");
}
