@import "src/assets/styles/helpers";

.inner {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    .tooltip {
      display: block;
    }
  }
}

.total {
  color: color("bombay");
  font-size: 1.2rem;
  line-height: 2rem;
  font-weight: 400;
  margin-left: 0.6rem;
}

.progress {
  height: 0.8rem;
  display: flex;
  max-width: 350px;
}

.bar {
  display: inline-flex;
  height: 100%;
  border-radius: 2px;
  margin: 0 0.1rem;
  &.blocked {
    background-color: color("widgets-torch-red");
  }
  &.send {
    background-color: color("widgets-crusta");
  }
  &.wait {
    background-color: color("widgets-green");
  }
  &.receive {
    background-color: color("widgets-blue");
  }
}

.tooltip {
  position: absolute;
  padding: 1.6rem 2.4rem;
  box-shadow: 2px 5px 10px rgba(25, 40, 60, 0.06);
  border-radius: 6px;
  background-color: var(--color-sidebar);
  width: 27.2rem;
  bottom: auto;
  right: 0;
  transform: translate(-3rem, -50%);
  z-index: 3;
}

.title {
  display: block;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.6rem;
  margin-bottom: 0.8rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.status {
  display: inline-flex;
  align-items: center;
  text-transform: capitalize;
  font-size: 1.2rem;
  line-height: 2.2rem;
  color: color("bali-hay");
}

.dot {
  display: inline-flex;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  margin-right: 1rem;

  &.blocked {
    background-color: color("widgets-torch-red");
  }
  &.send {
    background-color: color("widgets-crusta");
  }
  &.wait {
    background-color: color("widgets-green");
  }
  &.receive {
    background-color: color("widgets-blue");
  }
}

.value {
  font-weight: 500;
  line-height: 2.2rem;
}

.summary,
.startTime {
  margin-left: 1.5rem;
  line-height: 2.2rem;

  &Value {
    font-weight: 500;
    line-height: 2.2rem;
  }
}
