@import "src/assets/styles/helpers";

.element {
  display: flex;
  align-items: center;
  transition: $time-transition ease;
  &:hover {
    .text {
      color: color("bali-hay");
    }
  }

  &:focus,
  &:active {
    .text {
      color: color("btn-color-click");
    }
  }
}

.text {
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-weight: 600;
}

.icon {
  width: 1.1rem;
  height: 1.5rem;
  fill: var(--color-text);
  margin-right: 1.1rem;
}
