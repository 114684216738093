.element {
  display: flex;
  flex-direction: column;
  margin-top: 2.4rem;
  min-height: 10vh;

  table {
    tbody tr:first-child {
      border-top: none !important;
    }
  }
}

.footer {
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
}
