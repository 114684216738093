@import "src/assets/styles/helpers";

.element {
  display: flex;
  flex-direction: column;
}

.text {
  line-height: 2.2rem;
}
