@import "src/assets/styles/helpers";

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding: 3.2rem 0;
  background-color: color("overlay");
  z-index: 9999;
}

.element {
  position: relative;
  background-color: var(--color-sidebar);
  padding: 3.2rem;
  border-radius: 6px;
  width: 48rem;
  max-height: 100%;
  overflow: auto;
  @include scrollbar(4px, color("cornflower-blue"), var(--color-sidebar));

  &.isWide {
    width: 100%;
    max-width: 80rem;
    height: 100%;
    padding: 0;
  }
  &.noButton {
    padding: 0 3.2rem 3.2rem;
  }
}

.button {
  position: absolute;
  z-index: 3;
  top: 2.7rem;
  right: 3.2rem;
}

.title {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 500;
}
