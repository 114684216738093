@import "src/assets/styles/helpers";

.wrapper {
  margin-bottom: 2.2rem;
}

.element {
  &.isRow {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .fieldWrapper {
      margin-right: 3.6rem;
      margin-top: 0.8rem;
      margin-bottom: 0.8rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &.isGrid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(264px, 1fr));
    @include media("md") {
      width: 100%;
      grid-template-columns: repeat(4, 25%);
    }
  }
}

.title {
  display: block;
  color: color("bombay");
  font-size: 1.2rem;
  line-height: 2.4rem;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 0.8rem;
}

.fieldWrapper {
  display: flex;
  position: relative;
  margin-bottom: 1.6rem;
  .label {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
  }

  .box {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-panel-bg);
    width: 2.4rem;
    min-width: 2.4rem;
    height: 2.4rem;
    border-radius: 3px;
    margin-right: 1rem;
    padding: 0.5rem;
  }

  .icon {
    width: 100%;
    height: 100%;
    path {
      stroke: color("cornflower-blue");
    }
  }

  .text {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }

  &.isBgGray {
    .box {
      background-color: var(--color-background) !important;
    }
  }
  &.isDisabled {
    opacity: 0.4;
    cursor: default;
    pointer-events: none;
  }
}
