@import "src/assets/styles/helpers";

.name {
  display: block;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.6rem;
  color: var(--color-text);
}

.id {
  font-size: 1rem;
  line-height: 1.6rem;
  font-weight: 500;
  color: color("bali-hay");
  margin-top: 0.8rem;
}
