.container {
  position: relative;
  min-height: 100%;
}

.wrapper {
  display: inline-flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
