$colors: (
  "white": #ffffff,
  "dark-bg": #22242c,
  "charade": #292a33,
  "shark": #1a1b21,
  "big-stone": #19283c,
  "outer-space": #293238,
  "kimberly": #7a7ea4,
  "bali-hay": #8a97ad,
  "bombay": #b0b6be,
  "logo-grey": #b4b3b3,
  "iron": #d2d9dc,
  "whisper": #f5f6fa,
  "porcelain": #f6f7f8,
  "cornflower-blue": #566ff6,
  "heliotrope": #7555f6,
  "portage": #988ef8,
  "violet": #956fff,
  "light-violet": #b48bff,
  "lighter-violet": #d4a6ff,
  "pink": #f4c3ff,
  "perfume": #beaef9,
  "logo-blue": #439edd,
  "widgets-blue": #28aafd,
  "widgets-green": #00cc00,
  "widgets-turqoise": #43dfd3,
  "logo-green": #9fc043,
  "feijoa": #b4dd8b,
  "logo-yellow": #dedb50,
  "gold": #c89700,
  "widgets-yellow": #fde628,
  "light-apricot": #fcdfad,
  "widgets-backthorn": #f7a921,
  "widgets-crusta": #f88029,
  "flamingo": #ec6438,
  "carnation": #f55c5c,
  "widgets-sunset-orange": #f95549,
  "widgets-torch-red": #fb153c,
  "cyan-blue": #28aafd,
  "light-cyan-blue": #9ad8ff,
  "lighter-cyan-blue": #dff2ff,
  "btn-color-hover": #7086f8,
  "btn-color-click": #3a4eb8,
  "tropical-blue": #bbc6fa,
  "table-border": rgba(138, 151, 173, 0.2),
  "overlay": rgba(107, 114, 134, 0.71),
  "select-icon-color": #8091a7,
  "progress-bar": #f4f4f4,
);

:export {
  @each $name, $color in $colors {
    #{$name}: #{$color};
  }
}
