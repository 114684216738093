@import "src/assets/styles/helpers";

.element {
  position: relative;
  padding: 1.2rem 0;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  line-height: 2.2rem;
  width: 100%;
}

.index {
  display: inline-flex;
  margin-right: 1.6rem;
  width: 1.6rem;
  color: var(--color-text);
  user-select: none;
}

.title {
  width: 100%;
  display: flex;
}

.actions {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.delete {
  width: 1.6rem;
  height: 1.6rem;
  margin-left: 1.6rem;
  svg {
    width: 100%;
    height: 100%;
  }
}

.label {
  color: var(--color-text);
  user-select: none;
}

.add {
  width: 1.6rem;
  height: 1.6rem;
  margin-left: 2.6rem;
  svg {
    width: 100%;
    height: 100%;
    path {
      stroke: color("bali-hay");
    }
  }
  @include media("md") {
    &:hover {
      &:before {
        content: "Move to custom presets";
        position: absolute;
        z-index: 3;
        right: 0.8rem;
        bottom: -50%;
        width: max-content;
        font-size: 1.2rem;
        color: var(--color-text);
        background-color: var(--color-checkbox-bg);
        box-shadow: 2px 5px 10px rgba(25, 40, 60, 0.06);
        border-radius: 6px;
        padding: 0.5rem 1rem;
        transition: $time-transition ease;
      }
    }
  }
}
