@import "src/assets/styles/helpers";

.wrapper {
  position: relative;
  margin-bottom: 2.4rem;
}

.label {
  display: block;
  color: color("bombay");
  font-size: 1.2rem;
  line-height: 2.4rem;
  text-transform: uppercase;
  font-weight: 500;
  margin: 0;
}

.icon {
  width: 0.8rem;
  height: 0.8rem;
  path {
    stroke: var(--color-text);
  }
}

.error {
  position: absolute;
  bottom: -1.8rem;
  left: 0;
  color: color("widgets-torch-red");
  font-size: 1rem;
  line-height: 1.6rem;
}

.select {
  &.isPlain {
    [class$="-control"] {
      border: 1px solid transparent;
    }
  }

  [class$="-control"] {
    color: var(--color-text);
    border: 1px solid var(--color-input-border);
    background-color: var(--color-input-background);
    transition: none;

    &:hover {
      border: 1px solid var(--color-input-border);
      border-color: var(--color-input-border);
    }
    &:focus-within {
      border: 1px solid (var(--color-text));
    }
  }

  [class$="-menu"] {
    background-color: var(--color-panel-bg);
  }
  [class$="-singleValue"] {
    color: var(--color-text);
  }
  [class$="-option"] {
    color: var(--color-text);
  }
  [class$="-Input"] {
    color: var(--color-text);

    input {
      font-size: 1.2rem;
      line-height: 2.2rem;
      font-family: $poppins;
      color: var(--color-text);
    }
  }
  [class$="__multi-value"] {
    border: 1px solid var(--color-text-field-checked);
    margin-right: 0.4rem;
  }
  [class$="__multi-value__label"] {
    color: var(--color-text);
  }
  &.isError {
    [class$="-control"] {
      border: 1px solid color("widgets-torch-red");
    }
  }
}
