@import "src/assets/styles/helpers";

.content {
  margin-top: 2.1rem;
}

.text {
  display: block;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 400;
  //text-align: center;
  padding: 1.6rem 0 1.6rem 0;
  color: var(--color-text);
	border-bottom: 1px solid transparentize(color('bali-hay'), 0.8);
	margin-bottom: 1.6rem;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
