@import "src/assets/styles/helpers";

.element {
	width: 100%;
	border-radius: 6px;
	background-color: var(--color-panel-bg);
	overflow: hidden;
}

.title {
	font-weight: 500;
	font-size: 1.2rem;
	line-height: 2.4rem;
	padding: 0.8rem 1.6rem;
	color: color('bombay');
	text-transform: uppercase;
}

.table {
	width: 100%;
}

.td {
	border-top: 1px solid color("table-border");
}

.link {
	display: flex;
	padding: 1.2rem 1.6rem;
	color: var(--color-text);
	font-size: 1.2rem;
	line-height: 2.2rem;
	transition: $time-transition ease;

	&:hover {
		background-color: transparentize(color('bali-hay'), 0.9);
		color: color('cornflower-blue');
		.itemIn {
			color: color('cornflower-blue');
		}
	}
}

.itemIn {
	color: color('bali-hay');
	transition: $time-transition ease;

}
