@mixin scrollbar($size, $color-background, $color-thumb) {
	&::-webkit-scrollbar {
		width: $size;
		height: $size;
		background-color: $color-thumb;
	}

	&::-webkit-scrollbar-thumb {
		min-width: $size;
		max-height: $size;
		margin: 2px;
		background-color: $color-background;
	}

	&::-webkit-scrollbar-track {
		display: none;
	}
}
