@import "src/assets/styles/helpers";


.content {
	display: none;
}

.element {
	margin-bottom: 1.5rem;
	&.type-default {
		.heading {
			display: flex;
			align-items: center;
			cursor: pointer;
		}

		.icon {
			width: 2rem;
			height: 2rem;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			padding: 0.5rem;
			min-width: 2rem;
			background: color('white');
			border-radius: 3px;
			margin-right: 2rem;
			position: relative;
			.plank {
				width: 50%;
				height: 1px;
				border-radius: 30px;
				background: color('big-stone');
				position: absolute;
				&.isVertical {
					width: 1px;
					height: 50%;
				}
			}
		}

		.content {
			padding: 2rem 0 2rem 4rem;
		}

		.title {
			font-weight: 500;
			font-size: 1.8rem;
			line-height: 2.4rem;
		}

		&.isActive {
			& > .heading {
				.icon {
					background-color: color('cornflower-blue');
					.plank {
						background-color: color('white');
						&.isVertical {
							display: none;
						}
					}
				}
				.arrow {
					transform: rotate(90deg);
				}
			}

			& > .content {
				display: block;
			}
		}

		&.isLevel-1,
		&.isLevel-2 {
			& > .heading {
				.arrow {
					width: 0.8rem;
					height: 0.8rem;
					min-width: 0.8rem;
					margin-right: 1.4rem;
					fill: var(--color-text);
				}
				.title {
					font-weight: 400;
				}
			}

		}

		&.isLevel-1 {
			& > .content {
				padding-left: 0;
				@include media('md') {
					padding-left: 4rem;
				}
			}
		}
		&.isLevel-2 {
			& > .content {
				padding-left: 0;
				@include media('md') {
					padding-left: 2.4rem;
				}
			}
		}
	}


	&.type-oneLevel {
		margin-bottom: 0.8rem;
		padding: 0.8rem 0;
		border-bottom: 1px solid transparentize(color('bali-hay'), 0.8);
		.heading {
			display: flex;
			align-items: center;
			color: color('bali-hay');
			cursor: pointer;
			font-weight: 500;
			font-size: 1.2rem;
			line-height: 2.4rem;
			text-transform: uppercase;
			margin-bottom: 0.8rem;
		}

		.arrow {
			width: 0.8rem;
			height: 0.8rem;
			min-width: 0.8rem;
			margin-left: auto;
			fill: color('bali-hay');
			transform: rotate(90deg);
		}

		&.isActive {
			& > .content {
				display: block;
			}
			.arrow {
				transform: rotate(-90deg);
			}
		}
		&:last-child {
			border-bottom: 0;
			margin-bottom: 0;
		}
	}
}

