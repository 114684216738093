@import "src/assets/styles/helpers";

.element {
  padding: 1.2rem 0;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  line-height: 2.2rem;
  width: 100%;
}

.index {
  display: inline-flex;
  margin-right: 1.6rem;
  width: 1.6rem;
  color: var(--color-text);
  user-select: none;
}

.actions {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.delete {
  width: 1.6rem;
  height: 1.6rem;
  margin-left: 1.6rem;
  margin-right: 1.6rem;
  svg {
    width: 100%;
    height: 100%;
  }
}

.label {
  color: var(--color-text);
  user-select: none;
}

.select {
  [class$="-control"] {
    background-color: var(--color-input-background);
    color: var(--color-text);
    border: 1px solid var(--color-input-border);
    transition: none;

    &:hover {
      border: 1px solid var(--color-input-border);
    }
    &:focus-within {
      border: 1px solid var(--color-text);
    }
  }
  [class$="-menu"] {
    background-color: var(--color-panel-bg);
  }
  [class$="-singleValue"] {
    color: var(--color-text);
  }
  [class$="-option"] {
    color: var(--color-text);
  }
  [class$="-Input"] {
    color: var(--color-text);
  }

  &.isError {
    [class$="-control"] {
      border: 1px solid color("widgets-torch-red");
    }
  }
}
