@import "src/assets/styles/helpers";


.element {
	display: flex;
	height: 100vh;
}

.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	text-align: center;
}

.icon {
	width: 32rem;
	height: 32rem;
	margin-bottom: 3.2rem;
}

.title {
	font-size: 6.4rem;
	line-height: 1.2;
}

.subtitle {
	margin-bottom: 2.4rem;
	font-size: 2.4rem;
}

.text {
	color: color('bali-hay');
}

.link {
	color: color('cornflower-blue');
	border-bottom: 1px solid;

	&:hover {
		color: color('btn-color-hover');
	}
}
