@import "src/assets/styles/helpers";

.content {
	margin-top: 2.4rem;
}

.header {
	display: flex;
	align-items: center;
	margin-bottom: 1.6rem;
}

.headerImage {
	display: flex;
	align-items: center;
	max-width: 8.8rem;
	margin-right: 1.6rem;
	img {
		width: 100%;
	}
}

.group {
	margin-bottom: 1rem;
}

.progress {
	display: flex;
	width: 100%;
	margin: 0 -0.5px 1.6rem -0.5px;
}

.bar {
	display: inline-flex;
	height: 8px;
	border-radius: 2px;
	margin: 0 0.5px;
}

.legend {
	margin-bottom: 1.6rem;
}

.info {
	display: flex;
	justify-content: space-between;
}

.legendItem {
	display: flex;
	align-items: center;
	margin-right: 2.4rem;
	margin-bottom: 0.8rem;
	color: color('bali-hay');
	font-size: 1.2rem;
	line-height: 2.2rem;
	span {
		display: inline-flex;
		border-radius: 50%;
		width: 8px;
		min-width: 8px;
		height: 8px;
		margin-right: 8px;
	}
}
