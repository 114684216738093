@import "src/assets/styles/helpers";

.icon {
  fill: color("white");
}

.content {
  margin-top: 1.6rem;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 3.2rem;
}
