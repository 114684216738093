@import "src/assets/styles/helpers";

.progressBar {
  position: relative;
  width: 100%;
  min-width: 6rem;
  height: 0.8rem;
  background-color: color("progress-bar");
  border-radius: 2px;
  margin-right: 1.7rem;

  &.greenFill {
    background-color: color("widgets-green");
  }

  &.redFill {
    background-color: color("widgets-torch-red");
  }
}
