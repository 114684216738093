@import "src/assets/styles/helpers";

.wrapper {
  position: relative;
  display: inline-block;
  width: 4.8rem;
  height: 2.4rem;
}

.element {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: -1px;
  background-color: var(--color-panel-bg);
  transition: $time-transition ease;
  border-radius: 21px;
  border: 0.5px solid var(--color-input-border);
  &.isActive {
    &::before {
      background-color: color("cornflower-blue");
      transform: translateX(2.4rem);
    }
  }

  &.isDisabled {
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
    &::before {
      background-color: color("bali-hay");
      transform: translateX(0);
    }
  }
}

.element:before {
  position: absolute;
  content: "";
  height: 2.4rem;
  width: 2.4rem;
  left: 0;
  bottom: 0;
  background-color: var(--color-background);
  background-color: color("bali-hay");
  transition: $time-transition ease;
  border-radius: 50%;
}
