@import "src/assets/styles/helpers";

.element {
  position: fixed;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 8rem);

  background-color: var(--color-sidebar);
  transform: translateX(-100%);
  transition: transform $time-transition ease;

  &.isVisible {
    transform: translateX(0);
  }

  @include media("md") {
    max-width: 27.2rem;
  }
}

.scroller {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  @include scrollbar(4px, color("cornflower-blue"), var(--color-sidebar));
}

.alert {
  path {
    stroke: color("bali-hay");
    fill: color("bali-hay");
  }
}
